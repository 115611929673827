<template>
<h2 class="bright">Vos biens</h2>
  <button class="add-button" @click="displayForm()">
    <span class="text">Ajouter un bien</span>
  </button>
  <div v-if="isFormShown">
    <form action="" @submit.prevent="checkUpload()" autocomplete="off">
      <fieldset>
        <div class="errors" v-if="errors.length > 0">
          <p>Champs requis :</p>
          <ul>
            <li v-for="error in errors" :key="error">
              {{ error }}
            </li>
          </ul>
        </div>
        <span>Type de biens</span>
        <div class="type-selector">
          <input
            type="radio"
            name="propertyType"
            v-model="propertyType"
            id="maison"
            value="maison"
          />
          <label for="maison" class="blurred-button">Maison</label>

          <input
            type="radio"
            name="propertyType"
            v-model="propertyType"
            value="appartement"
            id="appartement"
          />
          <label for="appartement" class="blurred-button">Appartement</label>

          <input
            type="radio"
            name="propertyType"
            v-model="propertyType"
            value="parking"
            id="parking"
          />
          <label for="parking" class="blurred-button">Parking</label>

          <input
            type="radio"
            name="propertyType"
            v-model="propertyType"
            value="terrain"
            id="terrain"
          />
          <label for="terrain" class="blurred-button">Terrain</label>
        </div>
        <div class="caracteristics">
          <input
            type="number"
            placeholder="Surface"
            v-model="surface"
            name="surface"
            id="surface"
          />
          <input
            type="number"
            placeholder="Nombre de chambres"
            v-if="isbuilded"
            v-model="bedrooms"
            name="nbBedroom"
            id="nbBedroom"
          />
          <input
            type="number"
            placeholder="Nombre de pièces"
            v-if="isbuilded"
            v-model="rooms"
            name="nbRoom"
            id="nbRoom"
          />
          <input
            type="number"
            placeholder="Nombre de niveaux"
            v-if="isbuilded"
            v-model="levels"
            name="nbLevel"
            id="nbLevel"
          />
          <input
            type="text"
            v-model="adress"
            placeholder="Adresse"
            name="address"
            id="address"
            required
          />
          <input
            placeholder="Code postal / Ville"
            type="text"
            name=""
            id="zipcode"
            v-model="targetZipcode"
            @focus="clearZip()"
          />
          <div
            class="city-selector"
            v-if="citiesFromZipcode.length > 0 || citiesFromName.length > 0"
          >
            <div
              v-for="city in citiesFromZipcode"
              :key="city"
              @click="setCity(city.label, city.zip_code)"
            >
              {{ city.zip_code }} - {{ city.label }}
            </div>
            <div
              v-for="city in citiesFromName"
              :key="city"
              @click="setCity(city.label, city.zip_code)"
            >
              {{ city.zip_code }} - {{ city.label }}
            </div>
          </div>
            <input type="text" v-model="price" placeholder="Prix" name="" id="">
          <textarea
            placeholder="Notes complémentaires"
            v-model="observations"
            name=""
            id=""
            cols="30"
            rows="10"
          />
          <FileUpload
            :propertyID="newID"
            ref="uploader"
            @upload-complete="submitImageOnUpload"
            @upload-needed="() => (imageWaiting = true)"
            @upload-canceled="() => (imageWaiting = false)"
          />
        </div>
      </fieldset>
      <button class="submit" >Enregistrer</button>
    </form>
  </div>
  <div class="properties">
    <PropertyList :list="propertyListfromStore" />
  </div>
</template>
<script>
import cityList from "@/assets/cities.json";
import PropertyList from "@/components/property/PropertyList.vue";
import FileUpload from "../utils/FileUpload.vue";import router from "@/router";

export default {
  components: { PropertyList, FileUpload },
  data() {
    return {
      isFormShown: false,
      errors: [],
      imageWaiting: false,
      newID: 0,

      targetZipcode: "",
      propertyType: "",
      adress: "",
      city: "",
      surface: "",
      bedrooms: "",
      rooms: "",
      levels: "",
      observations: "",
      picture: "",
      price: "",
      propertyList: [],
    };
  },
  mounted() {
    this.newID = Date.now();
  },
  computed: {
    userRole() {
      return this.$store.state.stripeRole;
    },
    propertyListfromStore() {
      return this.$store.state.propertyList
    },
    citiesFromZipcode() {
      let cities = [];
      if (this.targetZipcode.length > 2) {
        cities = Object.values(cityList.cities).filter((city) =>
          city["zip_code"].startsWith(this.targetZipcode)
        );
      }

      return cities;
    },
    citiesFromName() {
      let cities = [];
      if (this.targetZipcode.length > 2) {
        cities = Object.values(cityList.cities).filter((city) =>
          city["label"].includes(this.targetZipcode)
        );
      }

      return cities;
    },
    isbuilded() {
      if (this.propertyType == "maison" || this.propertyType == "appartement") {
        return true;
      }
      return false;
    },
  },
  methods: {
    navigate(target) {
      router.push(target);
    },
    displayForm() {
      if (this.userRole) {
        this.isFormShown = !this.isFormShown
      } else {
        return this.navigate('/prices')
      }
    },
    checkUpload() {
      if (this.imageWaiting && this.validateForm()) {
        this.$refs.uploader.uploadFile();
      } else {
        this.addPorperty();
      }
    },
    submitImageOnUpload(imageName) {
      this.imageWaiting = false;
      this.picture = imageName;
      this.addPorperty();
    },
    async addPorperty() {
      if (this.validateForm()) {
        const propertyListDatas = {
          id: this.newID,
          propertyType: this.propertyType,
          adress: this.adress,
          city: this.targetZipcode,
          surface: this.surface,
          bedrooms: this.bedrooms,
          rooms: this.rooms,
          levels: this.levels,
          price: this.price,
          observations: this.observations,
          picture: this.picture,
        };
        await this.$store.dispatch('addProperty', propertyListDatas)
        this.saveProperty();
        this.clearForm();
        this.isFormShown = false;
        this.newID = Date.now();
      }
    },
    setCity(city, zipcode) {
      this.city = zipcode + " " + city;
      this.zipcode = zipcode;
      this.clearZip();
      this.targetZipcode = zipcode + " " + city;
    },
    clearZip() {
      this.targetZipcode = "";
    },
    removeCity(index) {
      this.contactCityList.splice(index, 1);
    },
    clearForm() {
      this.targetZipcode = "";
      this.propertyType = "";
      this.adress = "";
      this.city = "";
      this.surface = "";
      this.price = 0;
      this.bedrooms = "";
      this.rooms = "";
      this.levels = "";
      this.observations = "";
      this.$refs.uploader.unselectFile();
    },
    validateForm() {
      this.errors = [];
      if (this.propertyType == "") {
        this.errors.push("Type de bien");
      }
      if (this.targetZipcode == "") {
        this.errors.push("Code postal");
      }
      if (this.errors.length == 0) {
        return true;
      } else {
        return false;
      }
    },
    saveProperty() {
      this.$store.dispatch('savePropertyList')
    },
  },
};
</script>
<style lang="scss">
h4 {
  margin: 0 0 10px;
  padding: 0;
}

input[type="radio"],
input[type="checkbox"] {
  display: none;

  & + label {
    padding: 0.6em 0.7em;
    margin: 0 0.1em;
    border-radius: 0.4em;
    display: inline-block;
    background: #d5daff;
    color: #8187b9;
  }
}

input:checked + label {
  background: #6375ff;
  color: white;
}
.type-selector {
  display: flex;
  margin: 3px 0;

  label {
    margin: 0 2px;
  }
}
.caracteristics {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.properties {
  display: flex;
  flex-wrap: wrap;

  span {
    text-transform: capitalize;
  }
}

.property-item {
  a {
    color: #3b4a87;
  }
}

.errors {
  padding: 3px 0;
  font-size: 11px;
  color: #ff4e4e;

  p {
    padding: 0 3px 3px;
    font-weight: bold;
    margin: 0;
  }

  ul {
    display: inline-block;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      list-style: none;
      background: #ffe4e4;
      padding: 3px 6px;
      margin: 1px;
      border-radius: 3px;
      color: #d71717;
    }
  }
}
</style>

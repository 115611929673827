<template>
    <div class="file-upload flex">
        <label for="file" class="upload-button  blurred-button">
            <font-awesome-icon icon="camera" />
        </label>
        <input type="file" id="file" ref="file" @change="onFileChange" class="hidden" />
        <div class="preview" v-if="imgSrc">
            <font-awesome-icon icon="xmark" @click="unselectFile()"/>
            <img :src="imgSrc" class="img-preview" alt="">
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { getAuth } from 'firebase/auth'
export default {
    data() {
        return {
            file: '',
            imgSrc: ''
        }
    },
    props: {
        propertyID: Number
    },
    methods: {
        onFileChange(e) {
            const file = e.target.files[0];
            this.imgSrc = URL.createObjectURL(file);
            this.$emit('uploadNeeded')
        },
        unselectFile() {
            this.imgSrc = ''
            this.$refs.file.value = ''
            this.$emit('uploadCanceled')
        },
        uploadFile: function () {
            this.file = this.$refs.file.files[0];
            let formData = new FormData();
            formData.append('file', this.file);
            formData.append('userid', getAuth().currentUser.uid);
            formData.append('propertyID', this.propertyID);
            this.$refs.file.value = '';
            const that = this;
            axios.post('https://linkimmopro.fr/upload.php', formData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' }
                })
                .then(function (response) {
                    if (!response.data) {  alert('File not uploaded.'); } 
                    else { 
                        that.$emit('uploadComplete',response.data)
                        that.unselectFile()
                    }
                })
                .catch(function (error) {
                    console.log(error.code);
                });
        }
    },
}
</script>
<style lang="scss">
.file-upload {
    width: 100%;
}
.upload-button {
    height: 52px;
    width: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 11px;
    font-size: 2em;
}
.preview {
    position: relative;

    .fa-xmark {
        color: #ff4e4e;
        font-size: 17px;
        background: #d5daff;
        padding: 5px 6px;
        border-radius: 7px;
        vertical-align: top;
        margin: 0 5px;
        border: 1px solid white;
    }
}
.img-preview {
    max-width: 200px;
    max-height: 155px;
}
</style>
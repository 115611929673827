<template>
  <div
    class="list-item contact"
    @click="navigate(`${datas.id}`)"
  >
    <div class="company">{{ datas.nomAcheteur }}</div>
    <div class="name" v-html="datas.titre"></div>
    <div class="contact-infos">
      <div class="budget" v-if="datas.budget > 1">
        Budget <br /><strong>{{ datas.budget }} <span>€</span></strong>
      </div>
      <div class="budget" v-else>
        Budget <br /><strong>40000<span> € +</span></strong>
      </div>
      <div class="limit"  v-if='isDateExpired(datas.limite)'>
        Date limite <br /><strong>Expirée</strong>
      </div>
      <div class="limit" v-else>
        Date limite <br /><strong>{{ datas.limite }}</strong>
      </div>
    </div>
  </div>
</template>
<script>
    import router from "@/router";
export default {
  props: {
    datas:{},
    admin: String
  },
  methods: {
    navigate(target) {

        router.push('/veille/'+target);
    },
    isDateExpired(dateString) {
      // Convertir la date sous forme de texte en objet Date
      const dateParts = dateString.match(
        /(\d{1,2}) (\w+) (\d{4}) à (\d{2})h(\d{2})/
      );

      if (!dateParts) {
        console.error("Le format de date est incorrect");
        return null;
      }

      // Extraire les parties de la date
      const [, day, month, year, hours, minutes] = dateParts;

      // Associer les mois en français aux indices (octobre = 9, novembre = 10, etc.)
      const monthNames = [
        "janvier",
        "février",
        "mars",
        "avril",
        "mai",
        "juin",
        "juillet",
        "août",
        "septembre",
        "octobre",
        "novembre",
        "décembre",
      ];
      const monthIndex = monthNames.indexOf(month.toLowerCase());

      if (monthIndex === -1) {
        console.error("Le mois est incorrect");
        return null;
      }

      // Créer la date à partir des valeurs extraites
      const date = new Date(year, monthIndex, day, hours, minutes);

      // Comparer avec la date actuelle
      return date < new Date();
    },
  }
};
</script>

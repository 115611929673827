<template>
  <select
    name=""
    id=""
    v-model="selectedUser"
    @change="getUserMarketList(selectedUser);"
  >
    <option value="" disabled selected>Choisis un utlisateur</option>
    <option v-for="user in userList" :key="user.id" :value="{id:user.id,email:user.datas.JSONdatas.email}">
      {{ user.datas.JSONdatas.company }} - {{ user.datas.JSONdatas.nom }}
      {{ user.datas.JSONdatas.prenom }}
    </option>
  </select>
  <!-- id ajouteur : <textarea name="" id="" v-model="mashed"></textarea><button @click="mash()">go</button> -->
  <div v-if="selectedUser">
    <textarea name="" id="" v-model="update"  placeholder="entrer le code" style="width: 98%;"> </textarea>
    <!-- <button @click="validate()">Ajouter</button> -->
    <button @click="setCurrentDatas()">Prévisualiser</button>

    <div v-if="currentdatas">
      <div v-for="datas,index in currentdatas" :key="datas" class="infos-page">
        <div class="page-infos cont" v-if="datas">
          <textarea type="text" name="" id="" v-model="datas.titre" class="name toUppercase"></textarea>
          <div class="flex-wrap">
            <article class="avis">
              <strong>Avis d'expert</strong>
              <textarea name="" id="" v-model="datas.avis" style="width: 61%;"></textarea>
            </article>
            
            <article>
              <strong>Informations générales</strong>
              <textarea name="" id="" v-model="datas.acheteur"></textarea>
            </article>
            <article>
              <strong>DCE :</strong>
                <textarea name="" id="" v-model="datas.linkDCE"></textarea>
              <br /><a
                  @click="sendEmail(DCEstr)"
                  class="button flat"
                  >Demandez nous le DCE</a
                >
                <a
                  class="button flat"
                  v-if="datas.document"
                  :href="datas.document"
                  target="_blank"
                  >Télécharger le règlement de consultation</a>
                  <div class="upblock">
                    <input type="file" @change="handleFileChange($event)" accept=".pdf,.doc,.docx" />
                    <p v-if="uploadStatus">{{ uploadStatus }}</p>
                    <a class="button flat alt" @click="uploadFile(index)" :disabled="!selectedFile">Enregistrer le fichier</a>
                  </div>
            </article>

            <article>
              <strong>Contact et correspondance acheteur :</strong>
              <textarea name="" id="" v-model="datas.contact"></textarea>
            </article>
            <article>
              <strong>Date de dépôt exacte :</strong>
              <textarea name="" id="" v-model="datas.limite"></textarea>
            </article>
            <article>
              <strong>Période d'exécution du marché :</strong>
              <textarea name="" id="" v-model="datas.period"></textarea>
            </article>
            <article>
              <strong>Contenu / lots, Modalités :</strong>
              <textarea name="" id="" v-model="datas.contenu"></textarea>
            </article>
            <article>
              <strong>Zone géographique / Réseau ciblé :</strong>
              <textarea name="" id="" v-model="datas.cibleZoneGeo"></textarea>
            </article>
            <article>
              <strong>Volume, Durée et Budget du marché :</strong>
              <textarea name="" id="" v-model="datas.volume"></textarea>
            </article>
            
            <article>
              <strong>Attendus / objectifs / planning :</strong>
              <textarea name="" id="" v-model="datas.descriptif"></textarea>
            </article>
            <article>
              <strong>Critères Acheteur :</strong>
              <textarea name="" id="" v-model="datas.criteres"></textarea>
            </article>
            <article>
              <strong>Budget (pour miniature)</strong>
              <textarea name="" id="" v-model="datas.budget"></textarea>
            </article>
            <article>
              <strong>Nom de société (pour miniature)</strong>
              <textarea name="" id="" v-model="datas.nomAcheteur"></textarea>
            </article>
          </div>
        </div>
        <div v-else>Aucun marché correspondant</div>
      </div>
    </div>
    <div v-if="currentdatas" class="veille-list">
      <!-- // preview -->
      <marketListItem v-for="market in currentdatas" :key="market" :datas="market" admin=true></marketListItem>
      <button @click="validate()">Enregistrer</button>
    </div>

      <div>
        <h2>Précédents ajoûts</h2>
      </div>
      <div class="veille-list">
      <marketListItem v-for="market in marketListfromStore" :key="market" :datas="market" admin=true></marketListItem>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import {
  getFirestore,
  collection,
  getDocs /*where, query */,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import MarketListItem from "./Market-list-item.vue";

export default {
  props: {
    list: {},
  },
  components: {
    marketListItem : MarketListItem,
  },
  computed: {
    marketListfromStore() {
      return this.$store.state.userMarketList;
    },
    marketsFromTexarea() {
      // const preparedStr = JSON.stringify(this.update)
      // console.log(JSON.parse(preparedStr))
      return JSON.parse(this.update);
    },
  },
  data() {
    return {
      cool: true,
      fakeId: 2,
      userList: [],
      selectedUser: "",
      userEmail: "",
      auth: null,
      update: null,
      currentdatas: null,
      userRole:{},
      mashed:[],
      test: [
        {
          id: 6,
          acheteur:
            "Acheteur : SMICTOM Lot Garonne Baise<br>Adresse : 16 route de Saint Léon – ZAE de la Confluence, 47160 DAMAZAN<br>Contact : Non spécifié directement, mais communication possible via le site de profil acheteur.",
          titre:
            "Fourniture de kits de compostage individuel et composteurs bois pour les plateformes de compostage partagé.",
          linkDCE:
            "Les documents de la consultation sont accessibles sur le site de dématérialisation du profil d'acheteur.",
          contact:
            "Les informations spécifiques de contact ne sont pas directement mentionnées. Les correspondances peuvent être adressées via la plateforme de dématérialisation.",
          limite: "19 août 2024 à 12h00",
          period:
            "L'accord-cadre est conclu pour une durée initiale de 12 mois, avec possibilité de reconduction trois fois pour un an.",
          contenu:
            "Lot 1 : Fourniture de composteurs en plastique recyclé de 400 litres et bioseaux de 7 litres.<br>Lot 2 : Fourniture de composteurs bois de 800 litres.",
          cibleZoneGeo:
            "La fourniture sera destinée aux utilisateurs au sein du territoire du SMICTOM Lot Garonne Baise.<br>",
          volume:
            "Lot 1 : Jusqu'à 150 000 euros HT par an.<br> Lot 2 : Jusqu'à 20 000 euros HT par an.",
          descriptif:
            "Le marché vise à fournir des composteurs adaptés à la gestion des déchets organiques, contribuant ainsi à la réduction des déchets et à la promotion du compostage au sein de la communauté.",
          criteres:
            "Prix des prestations (50%)<br>Valeur technique de l'offre (40%)<br>Critère environnemental (10%), comprenant la réparabilité et les conditions de production des composteurs.",
          avis: "",
          budget: "120000",
        },
        {
          id: 8,
          acheteur:
            "Acheteur : loul SMICTOM Lot Garonne Baise<br>Adresse : 16 route de Saint Léon – ZAE de la Confluence, 47160 DAMAZAN<br>Contact : Non spécifié directement, mais communication possible via le site de profil acheteur.",
          titre:
            "Accord-cadre à bons de commande relatif à la fourniture de kits de compostage individuel et composteurs bois pour les plateformes de compostage partagé.",
          linkDCE:
            "Les documents de la consultation sont accessibles sur le site de dématérialisation du profil d'acheteur.",
          contact:
            "Les informations spécifiques de contact ne sont pas directement mentionnées. Les correspondances peuvent être adressées via la plateforme de dématérialisation.",
          limite: "19 août 2024 à 12h00",
          period:
            "L'accord-cadre est conclu pour une durée initiale de 12 mois, avec possibilité de reconduction trois fois pour un an.",
          contenu:
            "Lot 1 : Fourniture de composteurs en plastique recyclé de 400 litres et bioseaux de 7 litres.<br>Lot 2 : Fourniture de composteurs bois de 800 litres.",
          cibleZoneGeo:
            "La fourniture sera destinée aux utilisateurs au sein du territoire du SMICTOM Lot Garonne Baise.<br>",
          volume:
            "Lot 1 : Jusqu'à 150 000 euros HT par an.<br> Lot 2 : Jusqu'à 20 000 euros HT par an.",
          descriptif:
            "Le marché vise à fournir des composteurs adaptés à la gestion des déchets organiques, contribuant ainsi à la réduction des déchets et à la promotion du compostage au sein de la communauté.",
          criteres:
            "Prix des prestations (50%)<br>Valeur technique de l'offre (40%)<br>Critère environnemental (10%), comprenant la réparabilité et les conditions de production des composteurs.",
          avis: "",
          budget: "120000",
        },
      ],
      selectedMarket:{},
      selectedFile: null,
      uploadStatus: '',
      fileUrl: null, // Stocker l'URL du fichier
    };
  },
  mounted() {
    this.auth = getAuth();
    this.listCustomers();
    
  },
  methods: {
    navigate(target) {
      router.push(target);
    },
    sendEmail() {
      const emailData = {
        to: this.selectedUser.email,
        subject: "Des appels d'offre ont été ajoutés à votre espace",
        body: 'Une veille a été effectuée <br><a href="https://sourcing-ao.fr/#/opportunites">Accédez à votre espace Sourcing AO</a>',
        from: 'contact@g-m-e.fr'
      };

      fetch('https://sourcing-ao.fr/sendEmail.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(emailData)
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          alert('Email envoyé avec succès!');
        } else {
          alert('Erreur lors de l\'envoi de l\'email.');
        }
      })
      .catch(error => {
        console.error('Erreur:', error);
      });
    },
    getUserRole() {
      this.userRole = this.$store.state.stripeRole;
    },
    async listCustomers() {
      const db = getFirestore();
      const customersCollectionRef = collection(db, "userInfos");

      try {
        const querySnapshot = await getDocs(customersCollectionRef);
        querySnapshot.forEach((doc) => {
          let userDatas = {
            id: doc.id,
            datas: doc.data(),
          };
          this.userList.push(userDatas);
        });
      } catch (error) {
        console.error("Erreur lors de la récupération des customers: ", error);
      }
    },
    getUserMarketList(userInfos) {
      this.$store.dispatch("realtimeRetrieveMarkets", userInfos.id);
      this.getUserRole();
      this.formReset()
    },
    setCurrentDatas() {
      this.currentdatas = JSON.parse(this.update);
    },
    selectMarket() {
      this.selectedMarket = this.$store.getters.getMarketFromID(this.$route.params.id);
    },
    async validate() {
      let market = {};
      const datas = this.currentdatas;
      for (let index = 0; index < datas.length; index++) {
        market = datas[index];
        market.id=Date.now()+index;
        // if(this.fileUrl) {
        //   market.document="https"+this.fileUrl;
        // }
        await this.$store.dispatch("addMarket", market);
      }
      await this.$store.dispatch("saveMarketList", this.selectedUser.id);
      this.sendEmail()
      this.formReset()
    },
    formReset() {
      this.update = null
      this.currentdatas = null
    },
    mash() {
      let mashed = JSON.parse(this.mashed);
      for (let index = 0; index < mashed.length; index++) {
        const element = mashed[index];
        element.id=Date.now()+index
      }
      this.mashed = JSON.stringify(mashed);
    },
    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
      this.uploadStatus = '';
      this.fileUrl = null; // Réinitialiser le lien de téléchargement
    },
    async uploadFile(index) {
      this.uploadStatus = "Envoi en cours";
      if (!this.selectedFile) {
        this.uploadStatus = "Aucun fichier sélectionné.";
        return;
      }

      const fileType = this.selectedFile.type;
      if (!['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(fileType)) {
        this.uploadStatus = "Format de fichier non valide.";
        return;
      }

      const formData = new FormData();
      formData.append("file", this.selectedFile);

      try {
        const response = await fetch("upload-doc.php", {
          method: "POST",
          body: formData,
        });
        const result = await response.json();
        this.uploadStatus = result.message;
        this.fileUrl = result.fileUrl || null; // Stocker l'URL du fichier si disponible
        this.currentdatas[index].document = "https" + result.fileUrl || null
      } catch (error) {
        this.uploadStatus = "Erreur lors de l'upload.";
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
textarea {
  vertical-align: middle;
  padding:10px;
}
.budget,
.limit {
  display: inline-block;
    background: #cbffcb;
    padding: 2px 14px;
    color: #528352;
    margin: 2px;
    border-radius: 15px;
}
.limit {
  background: #ffe4e8;
  color: #d76666;
}
.contact {
  position: relative;

  .icon {
    position: absolute;
    top: 13px;
    right: 13px;
    color: #6d83df;
  }
  .name {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    display: block;
    color: #3574e7;
    padding: 17px 23px 0;
    font-size: 0.9rem;
    line-height: 1.1rem;
    font-weight: bold;
  }
  .contact-infos {
    padding: 10px;
  }
  .search-types {
    span + span:before {
      content: " - ";
    }
  }
  .list-item {
    border-radius: 10px;
    margin: 7px;
    line-height: 20px;
    display: block;
    text-decoration: none;
    background: white;
    width: 97%;
    max-width: 320px;
    box-shadow: 0 0 21px -6px #afb6d7;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.client {
  border: 1px solid #ffffff;
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  width: 40%;
  margin: 1%;
  color: #426ebd;
  box-shadow: 1px 1px 23px -13px grey;

  b {
    color: #3574e7;
  }
}
.preview {
  border: 2px solid #5dff5d;
}
textarea.name {
  width: 90%;
    margin: 0 auto;
    display: block;
}
</style>
